import styles from './styles.scss';
import { inBetween } from './inBetween';
import templateHtml from './template.html';
import { TABINDEX, RENDERED_CLASS } from './defaults';
const templateElement = document.createElement('template');
templateElement.innerHTML = templateHtml;
const KeySlideOffset = {
    ArrowLeft: -1,
    ArrowRight: 1,
};
const slideDirections = ['horizontal', 'vertical'];
const getTouchPagePoint = (e) => ({
    x: e.touches[0].pageX,
    y: e.touches[0].pageY,
});
const getMousePagePoint = (e) => ({
    x: e.pageX,
    y: e.pageY,
});
const slideAnimationPeriod = 1000 / 60;
export class HTMLImgComparisonSliderElement extends HTMLElement {
    constructor() {
        super();
        this.exposure = this.hasAttribute('value')
            ? parseFloat(this.getAttribute('value'))
            : 50;
        this.slideOnHover = false;
        this.slideDirection = 'horizontal';
        this.keyboard = 'enabled';
        this.isMouseDown = false;
        this.isFocused = false;
        this.onMouseMove = (e) => {
            if (this.isMouseDown || this.slideOnHover) {
                const currentPoint = getMousePagePoint(e);
                this.slideToPage(currentPoint);
            }
        };
        this.bodyUserSelectStyle = '';
        this.onMouseDown = (e) => {
            if (this.slideOnHover) {
                return;
            }
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onWindowMouseUp);
            this.isMouseDown = true;
            this.enableTransition();
            const currentPoint = getMousePagePoint(e);
            this.slideToPage(currentPoint);
            this.focus();
            this.bodyUserSelectStyle = window.document.body.style.userSelect;
            window.document.body.style.userSelect = 'none';
        };
        this.onWindowMouseUp = () => {
            this.isMouseDown = false;
            window.document.body.style.userSelect = this.bodyUserSelectStyle;
            window.removeEventListener('mousemove', this.onMouseMove);
            window.removeEventListener('mouseup', this.onWindowMouseUp);
        };
        this.isTouchComparing = false;
        this.hasTouchMoved = false;
        this.onTouchStart = (e) => {
            this.touchStartPoint = getTouchPagePoint(e);
            if (this.isFocused) {
                this.enableTransition();
                this.slideToPage(this.touchStartPoint);
            }
        };
        this.onTouchMove = (e) => {
            const currentPoint = getTouchPagePoint(e);
            if (this.isTouchComparing) {
                this.slideToPage(currentPoint);
                e.preventDefault();
                return false;
            }
            if (!this.hasTouchMoved) {
                const offsetY = Math.abs(currentPoint.y - this.touchStartPoint.y);
                const offsetX = Math.abs(currentPoint.x - this.touchStartPoint.x);
                if ((this.slideDirection === 'horizontal' && offsetY < offsetX) ||
                    (this.slideDirection === 'vertical' && offsetY > offsetX)) {
                    this.isTouchComparing = true;
                    this.focus();
                    this.slideToPage(currentPoint);
                    e.preventDefault();
                    return false;
                }
                this.hasTouchMoved = true;
            }
        };
        this.onTouchEnd = () => {
            this.isTouchComparing = false;
            this.hasTouchMoved = false;
        };
        this.onBlur = () => {
            this.stopSlideAnimation();
            this.isFocused = false;
            this.firstElement.classList.remove('focused');
        };
        this.onFocus = () => {
            this.isFocused = true;
            this.firstElement.classList.add('focused');
        };
        this.onKeyDown = (e) => {
            if (this.keyboard === 'disabled') {
                return;
            }
            if (this.isAnimating) {
                return;
            }
            this.isAnimating = true;
            const key = e.key;
            if (KeySlideOffset[key] === undefined) {
                return;
            }
            this.startSlideAnimation(KeySlideOffset[key]);
        };
        this.onKeyUp = (e) => {
            if (this.keyboard === 'disabled') {
                return;
            }
            if (!this.isAnimating) {
                return;
            }
            if (KeySlideOffset[e.key] === undefined) {
                return;
            }
            this.stopSlideAnimation();
        };
        this.resetDimensions = () => {
            this.imageWidth = this.offsetWidth;
            this.imageHeight = this.offsetHeight;
        };
        const shadowRoot = this.attachShadow({ mode: 'open' });
        const styleEl = document.createElement('style');
        styleEl.innerHTML = styles;
        if (this.getAttribute('nonce')) {
            styleEl.setAttribute('nonce', this.getAttribute('nonce'));
        }
        shadowRoot.appendChild(styleEl);
        shadowRoot.appendChild(templateElement.content.cloneNode(true));
        this.firstElement = shadowRoot.getElementById('first');
        this.firstImageContainerElement = shadowRoot.getElementById('firstImageContainer');
        this.secondElement = shadowRoot.getElementById('second');
    }
    get value() {
        return this.exposure;
    }
    set value(newValue) {
        const newExposure = parseFloat(newValue);
        if (newExposure === this.exposure) {
            return;
        }
        this.exposure = newExposure;
        this.enableTransition();
        this.setExposure();
    }
    get hover() {
        return this.slideOnHover;
    }
    set hover(newValue) {
        this.slideOnHover = newValue.toString().toLowerCase() !== 'false';
        this.removeEventListener('mousemove', this.onMouseMove);
        if (this.slideOnHover) {
            this.addEventListener('mousemove', this.onMouseMove);
        }
    }
    get direction() {
        return this.slideDirection;
    }
    set direction(newValue) {
        this.slideDirection = newValue.toString().toLowerCase();
        this.slide(0);
        this.firstElement.classList.remove(...slideDirections);
        if (!slideDirections.includes(this.slideDirection)) {
            return;
        }
        this.firstElement.classList.add(this.slideDirection);
    }
    static get observedAttributes() {
        return ['hover', 'direction'];
    }
    connectedCallback() {
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = TABINDEX;
        }
        this.addEventListener('dragstart', (e) => {
            e.preventDefault();
            return false;
        });
        const resizeObserver = new ResizeObserver(this.resetDimensions);
        resizeObserver.observe(this);
        this.setExposure(0);
        this.keyboard =
            this.hasAttribute('keyboard') &&
                this.getAttribute('keyboard') === 'disabled'
                ? 'disabled'
                : 'enabled';
        this.addEventListener('keydown', this.onKeyDown);
        this.addEventListener('keyup', this.onKeyUp);
        this.addEventListener('focus', this.onFocus);
        this.addEventListener('blur', this.onBlur);
        this.addEventListener('touchstart', this.onTouchStart, {
            passive: true,
        });
        this.addEventListener('touchmove', this.onTouchMove, {
            passive: false,
        });
        this.addEventListener('touchend', this.onTouchEnd);
        this.addEventListener('mousedown', this.onMouseDown);
        this.hover = this.hasAttribute('hover')
            ? this.getAttribute('hover')
            : false;
        this.direction = this.hasAttribute('direction')
            ? this.getAttribute('direction')
            : 'horizontal';
        this.resetDimensions();
        if (!this.classList.contains(RENDERED_CLASS)) {
            this.classList.add(RENDERED_CLASS);
        }
        if (this.querySelectorAll('[slot="before"], [slot="after"]').length > 0) {
            console.warn('<img-comparison-slider>: ' +
                'slot names "before" and "after" are deprecated and soon won\'t be supported. ' +
                'Please use slot="first" instead of slot="after", and slot="second" instead of slot="before".');
        }
    }
    disconnectedCallback() {
        if (this.transitionTimer) {
            window.clearTimeout(this.transitionTimer);
        }
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'hover') {
            this.hover = newValue;
        }
        if (name === 'direction') {
            this.direction = newValue;
        }
        if (name === 'keyboard') {
            this.keyboard = newValue === 'disabled' ? 'disabled' : 'enabled';
        }
    }
    setExposure(increment = 0) {
        this.exposure = inBetween(this.exposure + increment, 0, 100);
        this.firstElement.style.setProperty('--exposure', `${100 - this.exposure}%`);
    }
    slide(increment = 0) {
        this.setExposure(increment);
        const event = new Event('slide');
        this.dispatchEvent(event);
    }
    slideToPage(currentPoint) {
        if (this.slideDirection === 'horizontal') {
            this.slideToPageX(currentPoint.x);
        }
        if (this.slideDirection === 'vertical') {
            this.slideToPageY(currentPoint.y);
        }
    }
    slideToPageX(pageX) {
        const x = pageX - this.getBoundingClientRect().left - window.scrollX;
        this.exposure = (x / this.imageWidth) * 100;
        this.slide(0);
    }
    slideToPageY(pageY) {
        const y = pageY - this.getBoundingClientRect().top - window.scrollY;
        this.exposure = (y / this.imageHeight) * 100;
        this.slide(0);
    }
    enableTransition() {
        const transitionTime = 100;
        this.firstElement.style.setProperty('--transition-time', `${transitionTime}ms`);
        this.transitionTimer = window.setTimeout(() => {
            this.firstElement.style.setProperty('--transition-time', `0ms`);
            this.transitionTimer = null;
        }, transitionTime);
    }
    startSlideAnimation(offset) {
        let lastTimestamp = null;
        const slide = (now) => {
            if (lastTimestamp === null) {
                lastTimestamp = now;
            }
            const interval = now - lastTimestamp, distance = (interval / slideAnimationPeriod) * offset;
            this.slide(distance);
            if (this.isAnimating) {
                window.requestAnimationFrame(slide);
                lastTimestamp = now;
            }
        };
        window.requestAnimationFrame(slide);
    }
    stopSlideAnimation() {
        this.isAnimating = false;
    }
}
if (typeof window !== 'undefined') {
    window.customElements.define('img-comparison-slider', HTMLImgComparisonSliderElement);
}
